<template>
  <div id="ProductList">
    <FirstCategory />
    <div class="row main">
      <Menu />
      <List />
    </div>
  </div>
</template>

<script>
import FirstCategory from '../components/ProductList/Category/index';
import Menu from '../components/ProductList/Menu';
import List from '../components/ProductList/List';
import { GetMetaData } from '@/common/meta';
export default {
  name: 'ProductList',
  components: {
    FirstCategory,
    Menu,
    List,
  },
  data() {
    return {
      meta_data: null,
    };
  },
  created() {
    this.meta_data = GetMetaData('Products', '', '');
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
