<template src="./template.html"></template>

<script>
export default {
  name: "ProductListList",
  data() {
    return {
      sort_by: "Default",
    };
  },
  methods: {
    GetSeries(val) {
      let title = "";
      title = this.series.filter((item) => item.ProductDirID == val)[0].Title;
      return title;
    },
    products() {
      let tmp_products = JSON.parse(JSON.stringify(this.$store.state.products));
      if (this.$route.params.type == "all") {
        tmp_products = this.SortProducts(tmp_products);
        return tmp_products;
      } else if (this.$route.params.type == "hot") {
        return this.$store.state.products.filter((item) => item.TagHot == "Y");
      } else if (this.$route.params.type == "new") {
        return this.$store.state.products.filter((item) => item.TagNew == "Y");
      } else if (this.$route.params.type == -1) {
        return "not found";
      } else if (
        this.$store.state.products.filter(
          (item) => item.ProductDirID == this.$route.params.type
        ).length <= 0
      ) {
        return "not found";
      } else {
        return this.$store.state.products.filter(
          (item) => item.ProductDirID == this.$route.params.type
        );
      }
    },
    SortProducts(products) {
      if (this.sort_by == "Default") {
        return products.sort(function (a, b) {
          return a.Seq - b.Seq;
        });
      } else {
        products = products.sort(function (a, b) {
          const a_date = new Date(a.NewTime);
          const b_date = new Date(b.NewTime);
          return a_date - b_date;
        });
        if (this.sort_by == "上市時間由舊到新") {
          return products;
        } else if (this.sort_by == "Latest") {
          return products.reverse();
        }
      }
    },
    CheckProductInCart(item) {
      return this.shop_cart == null
        ? ""
        : this.shop_cart.indexOf(item.ProductID) != -1
        ? "active"
        : "";
    },
  },
  computed: {
    shop_cart() {
      return this.$store.state.shop_cart;
    },
    category() {
      return this.$store.state.category;
    },
    series() {
      return this.$store.state.series;
    },
  },
  filters: {
    wordbreak(val) {
      let word = "";
      let count = 0;
      let max_count = 100;

      if (window.innerWidth <= 640) {
        max_count = 100;
      }
      for (let i = 0; i < val.length; i++) {
        let tmp_count = count;
        let tmp_word = word;
        // eslint-disable-next-line no-control-regex
        // if (val[i].match(/[\u0000-\u00ff]/g)) {
        //   count += 1;
        //   word += val[i];
        // } else if (val[i].match(/[\u4e00-\u9fa5]/g)) {
        //   count += 2;
        //   word += val[i];
        // } else if (val[i].match(/[\uff00-\uffff]/g)) {
        //   count += 2;
        //   word += val[i];
        // }
        count += 1;
        word += val[i];

        if (tmp_count < max_count && count > max_count) {
          word = tmp_word;
          break;
        } else if (count == max_count) {
          break;
        }
      }
      if (word == val) {
        return word;
      } else {
        return word + "...";
      }
    },
    product_in_cart_text(val) {
      return val == "active" ? "Already In Order" : "Add To Order";
    },
  },
};
</script>